<template>
  <v-container>
    <!-- keyboard -->
    <v-row id="keyboard" justify="center">
      <v-col xs="12" sm="6" md="6" lg="4" xl="3">
        <v-row justify="center">
          <Drop
            :style="{
              width: '100%'
            }"
            @drop="handleDrop($event)"
          >
            <v-col cols="12">
              <input
                :style="{
                  background: backGroundColor,
                  color: backGroundColor !== 'white' ? 'white' : 'black'
                }"
                class="kakko-input-blank"
                type="text"
                minlength="1"
                required="required"
                :value="displayText"
                :readonly="true"
              />
            </v-col>
          </Drop>
        </v-row>
        <v-row v-if="showCorrectAnswer" justify="center">
          <v-col cols="12">
            <input
              :style="{ width: '100%', background: 'green', color: 'white' }"
              class="kakko-input-blank"
              type="text"
              minlength="1"
              required="required"
              :value="correctAnswerText"
              :readonly="true"
            />
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-4 mb-2">
          <span
            :style="{
              textAlign: 'center',
              color: $vuetify.theme.themes.light.primary
            }"
            class="text-body-2"
          >
            {{ $t('dragDropInstruction') }}
          </span>
        </v-row>
        <!-- <v-row justify="center">
               <span :style="{textAlign:'center'}" class="blink" v-if="tryagain"> 'Try Again!!!' </span>
           </v-row> -->
        <v-row justify="center" class="mb-2">
          <v-col class="pa-1 ma-1" v-for="(l, key) in answers" :key="key">
            <LetterButton
              :letter="l"
              :game-over="false"
              :draggable="true"
              :key="l + key"
              @check="check(letter)"
              v-cloak
            />
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-5 mb-5">
      <v-btn
        v-if="!showNextQuestionBtn && practiceCount !== 0"
        raised
        color="primary"
        @click="submit()"
        >{{ $t('Submit Answer') }}</v-btn
      >
      <v-btn
        v-if="showNextQuestionBtn && !practiceCount !== 0"
        raised
        color="primary"
        @click="nextQuestion()"
        >Next Question</v-btn
      >
    </v-row>
    <v-row v-if="practiceCount === 0" align="center" justify="center">
      <span
        :style="{
          textAlign: 'center',
          color: $vuetify.theme.themes.light.primary
        }"
        class="text-h6 mr-3"
        >Your skill level is:
      </span>
      <v-icon v-for="i in skillRating" :key="'star' + i" :color="i.color">
        {{ i.icon }}
      </v-icon>
    </v-row>

    <v-row v-else align="center" justify="center">
      <Scorecard
        :wrongAttempts="wrongAttempts"
        :practiceCount="practiceCount"
        :totalAttempts="totalAttempts"
      />
    </v-row>
    <v-row
      v-if="practiceCount === 0"
      align="center"
      justify="center"
      class="mt-5"
    >
      <v-btn raised="true" color="secondary" @click="refresh()">{{
        $t('Try Again')
      }}</v-btn>
    </v-row>
    <GoBackButon />
  </v-container>
</template>

<script>
import LetterButton from './DraggableLetterButton'
import { Drop } from 'vue-drag-drop'
import GoBackButon from './GoBackButton'
import Scorecard from './Scorecard'
import Utils from '../util/Utils'
export default {
  mounted() {
    this.refresh()
  },
  components: {
    LetterButton,
    Drop,
    GoBackButon,
    Scorecard
  },
  computed: {
    letters() {
      return this.$store.state.jointLetters
    },
    answers() {
      return this.$store.state.jointLetters.map((l) => l.answer)
    },
    displayText() {
      return this.selectedLetter
        ? `${this.selectedLetter.letters.split(',').join(' + ')} = ${
            this.answer
          }`
        : ''
    },
    correctAnswerText() {
      return this.selectedLetter
        ? `${this.selectedLetter.letters.split(',').join(' + ')} = ${
            this.selectedLetter.answer
          }`
        : ''
    }
  },
  methods: {
    refresh() {
      this.randomIndexes = Utils.shuffle(this.letters.map((l, i) => i))
      const temp = this.randomIndexes.slice(
        0,
        this.totalAttempts - this.randomIndexes.length
      )
      this.randomIndexes = this.randomIndexes.concat(temp)
      this.practiceCount = this.totalAttempts
      this.selectQuestion()
      this.wrongAttempts = 0
      this.skillRating = []
      this.showCorrectAnswer = false
      this.showNextQuestionBtn = false
      this.backGroundColor = 'white'
    },
    selectQuestion() {
      this.selectedLetter =
        this.letters[
          this.randomIndexes[this.totalAttempts - this.practiceCount]
        ]
      this.answer = ''
    },
    confettiDrop() {
      Utils.dropConfetti(this.$confetti)
    },
    nextQuestion() {
      this.showCorrectAnswer = false
      this.backGroundColor = 'white'
      this.selectQuestion()
      this.showNextQuestionBtn = false
    },
    submit() {
      this.practiceCount--

      if (this.selectedLetter.answer === this.answer) {
        this.backGroundColor = 'green'
      } else {
        this.wrongAttempts++
        this.backGroundColor = 'red'
        this.showCorrectAnswer = true
      }
      if (this.practiceCount === 0) {
        let score = this.totalAttempts - this.wrongAttempts
        this.skillRating = []
        while (score >= this.totalAttempts / 5) {
          this.skillRating.push({
            icon: 'mdi-star',
            color: this.$vuetify.theme.themes.light.secondary
          })
          score = score - this.totalAttempts / 5
        }
        if (score >= this.totalAttempts / 10) {
          this.skillRating.push({
            icon: 'mdi-star-half-full',
            color: this.$vuetify.theme.themes.light.secondary
          })
        }
        while (this.skillRating.length < 5) {
          this.skillRating.push({ icon: 'mdi-star-outline', color: '' })
        }
        if (
          this.skillRating.filter((s) => s.icon === 'mdi-star-outline')
            .length <= 1
        ) {
          this.confettiDrop()
        }
      } else {
        this.showNextQuestionBtn = true
      }
    },
    handleDrop(data) {
      this.answer = data.letter
    }
  },
  data: () => ({
    selectedLetter: undefined,
    selectedVowel: '',
    answer: '',
    tryagain: false,
    totalAttempts: 50,
    skillRating: [],
    ratingTurnedOn: false,
    practiceCount: 0,
    wrongAttempts: 0,
    practiceAgain: false,
    backGroundColor: 'white',
    showCorrectAnswer: false,
    showNextQuestionBtn: false,
    randomIndexes: []
  })
}
</script>
<style scoped>
.letter {
  padding: 1px;
  margin: 5px;
  text-shadow: 0 1px 1px #eee;
  font: normal 40px 'Lucida Sans Unicode', 'Lucida Grande', 'Arial Unicode MS',
    sans-serif;
}
/* D. keyboard */
#keyboard {
  margin-left: 2px;
  margin-right: 2px;
}
.keyboardWidth {
  max-width: 400px;
}
.keyboard-row-letter {
  height: 40px;
  width: 40px;
  font-size: x-large;
}
.blink {
  animation: blink 1s linear infinite;
  color: red;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
