var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"id":"keyboard","justify":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('Drop',{style:({
            width: '100%'
          }),on:{"drop":function($event){return _vm.handleDrop($event)}}},[_c('v-col',{attrs:{"cols":"12"}},[_c('input',{staticClass:"kakko-input-blank",style:({
                background: _vm.backGroundColor,
                color: _vm.backGroundColor !== 'white' ? 'white' : 'black'
              }),attrs:{"type":"text","minlength":"1","required":"required","readonly":true},domProps:{"value":_vm.displayText}})])],1)],1),(_vm.showCorrectAnswer)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('input',{staticClass:"kakko-input-blank",style:({ width: '100%', background: 'green', color: 'white' }),attrs:{"type":"text","minlength":"1","required":"required","readonly":true},domProps:{"value":_vm.correctAnswerText}})])],1):_vm._e(),_c('v-row',{staticClass:"mt-4 mb-2",attrs:{"justify":"center"}},[_c('span',{staticClass:"text-body-2",style:({
            textAlign: 'center',
            color: _vm.$vuetify.theme.themes.light.primary
          })},[_vm._v(" "+_vm._s(_vm.$t('dragDropInstruction'))+" ")])]),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"center"}},_vm._l((_vm.answers),function(l,key){return _c('v-col',{key:key,staticClass:"pa-1 ma-1"},[_c('LetterButton',{key:l + key,attrs:{"letter":l,"game-over":false,"draggable":true},on:{"check":function($event){return _vm.check(_vm.letter)}}})],1)}),1),_c('v-row')],1)],1),_c('v-row',{staticClass:"mt-5 mb-5",attrs:{"align":"center","justify":"center"}},[(!_vm.showNextQuestionBtn && _vm.practiceCount !== 0)?_c('v-btn',{attrs:{"raised":"","color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('Submit Answer')))]):_vm._e(),(_vm.showNextQuestionBtn && !_vm.practiceCount !== 0)?_c('v-btn',{attrs:{"raised":"","color":"primary"},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v("Next Question")]):_vm._e()],1),(_vm.practiceCount === 0)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"text-h6 mr-3",style:({
        textAlign: 'center',
        color: _vm.$vuetify.theme.themes.light.primary
      })},[_vm._v("Your skill level is: ")]),_vm._l((_vm.skillRating),function(i){return _c('v-icon',{key:'star' + i,attrs:{"color":i.color}},[_vm._v(" "+_vm._s(i.icon)+" ")])})],2):_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('Scorecard',{attrs:{"wrongAttempts":_vm.wrongAttempts,"practiceCount":_vm.practiceCount,"totalAttempts":_vm.totalAttempts}})],1),(_vm.practiceCount === 0)?_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"raised":"true","color":"secondary"},on:{"click":function($event){return _vm.refresh()}}},[_vm._v(_vm._s(_vm.$t('Try Again')))])],1):_vm._e(),_c('GoBackButon')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }